
@import '../node_modules/bulma/css/bulma.min.css';
@import '../node_modules/bulma-social/css/all.min.css';
@import '../node_modules/@fortawesome/fontawesome-free/css/brands.min.css';
@import url('https://fonts.googleapis.com/css2?family=Anek+Telugu:wght@100;200;300;400&display=swap');

html, body {
  height: 100%;
  background:linear-gradient(rgba(0,0,100,0.5),transparent);
  background-color:darkgreen; 
  background-repeat: no-repeat;
}

.main {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

body > footer {
  margin-top: auto;
}

.section {
  flex: 1;
}

.box {
  box-shadow: 3px 3px 3px rgba(170, 170, 170, 0.3);
}

@media only screen and (max-width: 768px) {
.navbar-menu {
  display: block;
  opacity: 0;
  position: absolute;   /* or float: left; width: 100%;*/
  left: 0;
  right: 0;
  transform: translateY(-50%);
  transition: all .3s ease-in-out;
  pointer-events: none;
}

.navbar-menu.is-active {
  opacity: 1;
  transform: none;
  pointer-events: auto;
}
}